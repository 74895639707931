// TODO
// npm uninstall js-offcanvas mmenu-js slideout-mod animejs



// NPM PACKAGES

// import jQuery from 'jquery'
// window.$ = window.jQuery = jQuery

import 'jquery'
import 'popper.js'
import 'bootstrap'

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import '@fancyapps/fancybox'

// import 'swiper'
import Swiper from 'swiper'
global.Swiper = Swiper;


// PROJECT JS MODULES
import {FormManager} from './modules/form';

import {SeoLink} from './modules/seolink';
import {ScrollTo} from './modules/scrollto';
import {Sticky} from './modules/sticky';



// import {Tariff} from './modules/tariff';
// import {Menu} from './modules/menu';
// import {OffCanvas} from './modules/offcanvas-hiraku2';

// SHOP JS MODULES

// import {CookiePolicy, Notify, Agree} from './modules/common';
import {Cart, Voucher, WishList, CookiePolicy, Notify, Agree} from './modules/common';
import {Checkout} from './modules/checkout';
import Search from './modules/search';


import {AjaxPagination} from './modules/ajax-pagination';
// import {ProductFilters} from './modules/filters';
import {Product} from './modules/product';

document.addEventListener("DOMContentLoaded", () => {

    // Common
    global.Notify = new Notify();
    global.Agree = new Agree();
    // global.WishList = new WishList();
    // global.Voucher = new Voucher();
    global.Cart = new Cart();
    global.Checkout = new Checkout();


    // new Tariff();

    // FORM
    const forms = new FormManager('form.ajax-form');
    forms.init();

    // CATALOG MENU
    // const menu = new Menu();


    // ScrollTo
    const scrollTo = new ScrollTo();
    scrollTo.init();

    // Sticky
    const sticky = new Sticky();


    // SEO HIDE for links
    const seoLinks = new SeoLink('[data-link]');
    seoLinks.init();

    // CookiePolicy
    const cookiePolicy = new CookiePolicy();
    cookiePolicy.init();

    // // Product
    const product = new Product();
    product.init();
    //
    // // Product Filters
    // const productFilters = new ProductFilters();
    // productFilters.init();

    // Search
    const search = new Search();
    search.init();


    // Off Canvas
    // new OffCanvas();


    // INFINITY
    const ajaxPagination = new AjaxPagination('.js-InfinityContainer');


    // DROPDOWN
    $(document).on('click', '.dropdown--freeze', function (e) {
        e.stopPropagation();
    });

});


window.onload = () => {

    $().fancybox({
        selector: '[data-toggle="lightbox"]',
        // selector: '[data-fancybox]',
        hash: false,
        protect: false,
        backFocus : false,
        buttons: [
            "thumbs",
            "close"
        ],
        // caption : function( instance, item ) {
        //     return $(this).parent('figure').find('figcaption').html();
        // }
    });
};


// LAZYSIZES
//add support for background images:
// document.addEventListener('lazybeforeunveil', function(e){
//     const bg = e.target.getAttribute('data-bg');
//     if(bg){
//         e.target.style.backgroundImage = 'url(' + bg + ')';
//     }
// });
//add AJAX loading blocks
//<div class="lazyload" data-ajax="my-url.html"></div>
// $(document).on('lazybeforeunveil', function(e){
//     const ajax = $(e.target).data('ajax');
//     if(ajax){
//         $(e.target).load(ajax);
//     }
// });



// PROJECT STYLESHEETS
import '../scss/app.scss';
