// import anime from 'animejs/lib/anime.es.js';

export class Product {

    constructor() {

        this.selector = {
            item : '.product-item',
            card : '.product-card',
            price: {
                old: '.js-priceOld',
                current: '.js-priceCurrent',
                old_vip: '.js-priceVipOld',
                current_vip: '.js-priceVipCurrent',
                next_discount: '.js-nextDiscount'
            },
            modal: {
                preview: '#productPreviewModal'
            },
            checkout: '#cleverCheckout'
        };

        this.default = {
            quantity: {
                min: 1,
                max: 5000,
                step: 1
            },
            api: {
                amount: 'index.php?route=checkout/calculate/amount_product',
                preview: 'index.php?route=product/product/modal'
            }
        };

        this.$products = $(this.selector.item);

        this.$product = $(this.selector.card);

        this.$checkout = $(this.selector.checkout);


        this.ajaxQuantityTimeout = 0;

        this.$modalPreview = null;
    }


    init() {

        if (!this.$products.length && !this.$product.length && !this.$checkout.length) {
            return;
        }

        const plugin = this;


        this._initModalPreview();

        this.$modalPreview.on("hidden.bs.modal", function () {
            plugin.$modalPreview.find('.infinity-loader').removeClass('d-none');
            plugin.$modalPreview.find('.modal-title').html('Загружаем ...');
            plugin.$modalPreview.find('.product-preview').html('').attr('data-id', '');
        });

        $(document).on('click', '[data-toggle="product-modal"]', function (e) {
            e.preventDefault();
            e.stopPropagation();
            plugin._modal($(this).data('id'));
        });


        $(document).on('change', '[name^="option["]', function () {
            plugin._livePriceReload($(this));
        });

        $(document).on('change', 'input[name^=quantity]', function () {

            plugin._livePriceReload($(this));

            // const $outer = plugin.getOuter($(this));
            //
            // const product_id = Number($outer.data('id'));
            // const quantity = plugin.getInputQuantity($(this));
            //
            // if (!product_id || !quantity) {
            //     return;
            // }
            //
            // let data = {
            //     'product_id' : product_id,
            //     'quantity' : quantity,
            //     // 'option' : {}
            // };
            //
            // // console.log(plugin.default.api.amount);
            //
            // $.ajax({
            //     'url': plugin.default.api.amount,
            //     'type' : 'post',
            //     'dataType': 'json',
            //     'data': data
            // }).done(function (json) {
            //
            //     // console.log(json);
            //
            //     if (json.success) {
            //         plugin._livePriceUpdate($outer, json.success);
            //     }
            // });

        });


        $(document).on('click', '.js-productQuantityButton', function () {
            plugin._switchQuantity($(this));
        });


        $(document).on('click', '.js-productNextDiscount', function () {

            const quantity = Number($(this).data('quantity'));
            const $outer = plugin.getOuter($(this));

            if (quantity && $outer.length) {

                const $input = $outer.find('input[name^=quantity]');

                if ($input.length) {
                    $input.val(quantity).trigger('change');
                }
            }
        });
    }

    _modal(product_id) {

        const plugin = this;

        if (!product_id) {
            return;
        }

        plugin.$modalPreview.modal('show');

        $.ajax({
            'url': plugin.default.api.preview + '&id=' + product_id,
            'type' : 'get',
            'dataType': 'json',
        }).done(function (json) {

            // console.log(json);

            if (json.success) {
                plugin.$modalPreview.find('.infinity-loader').addClass('d-none');
                plugin.$modalPreview.find('.modal-title').html(json.success.name);
                plugin.$modalPreview.find('.product-preview').html(json.success.html).attr('data-id', product_id);
            }
        });
    }


    _initModalPreview() {

        const plugin = this;

        if (!plugin.$modalPreview) {

            const modalID = plugin.selector.modal.preview.replace('#', '');

            let html = '<div id="' + modalID + '" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="' + modalID + 'Label" aria-hidden="true">';
            html += '        <div class="modal-dialog modal-lg" role="document">';
            html += '            <div class="modal-content">';
            html += '                <div class="modal-header pt-1 pb-0">';
            html += '                   <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
            html += '                </div>';
            html += '                <div class="modal-body px-lg-4 pt-0 pb-5">';
            html += '                   <h4 class="modal-title mb-md-4 px-md-4" id="' + modalID + 'ModalLabel">Загружаем ...</h4>';
            html += '                   <div class="product-preview"></div>';
            html += '                   <div class="infinity-loader"><div class="loader-item-1"></div><div class="loader-item-2"></div><div class="loader-item-3"></div><div class="loader-item-4"></div><div class="loader-item-5"></div></div>';
            html += '                </div>';
            // html += '                <div class="modal-footer justify-content-center">';
            // html += '                   <button type="button" class="btn btn-primary">Добавить в корзину</button>';
            // html += '                </div>';
            html += '            </div>';
            html += '        </div>';
            html += '    </div>';


            $('body').append(html);

            plugin.$modalPreview = $(plugin.selector.modal.preview);

            // plugin.$modalPreview.modal();
        }
    }





    _livePriceReload($element) {

        if (!$element.length) {
            return;
        }

        const plugin = this;

        const $outer = plugin.getOuter($element);



        console.log($element);

        if (!$outer.length) {
            return;
        }


        const product_id = Number($outer.data('id'));
        const quantity = plugin.getInputQuantity($outer.find('input[name^=quantity]'));

        if (!product_id || !quantity) {
            return;
        }

        let options = {};
        $outer.find('[name^="option["]').each(function (i, elem) {

            let pid = $(elem).attr('name').replace('option[', '').replace(/]+$/, '');
            let type = $(elem).attr('type') || null;

            if (type === 'radio' || type === 'checkbox') {
                if ($(elem).is(":checked")) {
                    options[pid] = $(elem).val();
                }
            } else {
                options[pid] = $(elem).val();
            }
        });


        let data = {
            'product_id' : product_id,
            'quantity' : quantity,
            'option' : options
        };

        // console.log('Price reload...');
        // console.log(data);

        $.ajax({
            'url': plugin.default.api.amount,
            'type' : 'post',
            'dataType': 'json',
            'data': data
        }).done(function (json) {

            // console.log(json);

            if (json.success) {
                plugin._livePriceUpdate($outer, json.success);
            }
        });


    }


    _livePriceUpdate($outer, data) {

        if (!$outer.length || !data) {
            return;
        }

        const plugin = this;

        const effect = 'fadeIn';
        const duration = 100;

        const $elements = {
            price: {
                old: $outer.find(plugin.selector.price.old),
                current: $outer.find(plugin.selector.price.current),
            },
            price_vip: {
                old: $outer.find(plugin.selector.price.old_vip),
                current: $outer.find(plugin.selector.price.current_vip),
            },
        };

        for (let price_type in $elements) {
            if (!$elements.hasOwnProperty(price_type)) {
                continue;
            }

            let suffix = price_type.replace('price', '');

            let price = data['price' + suffix];

            let special = data['special' + suffix];
            if (data.amount.hasOwnProperty('discount') && data.amount.discount.hasOwnProperty('price' + suffix)) {
                special = data.amount.discount['price' + suffix];
            }

            if (special && price) {
                $elements[price_type].old.removeClass('d-none');
                plugin._animateTextUpdate($elements[price_type].old, price);

                $elements[price_type].current.addClass('price--special');
                plugin._animateTextUpdate($elements[price_type].current, special, effect, duration);
            } else if (data.price) {
                $elements[price_type].old.addClass('d-none').text('');

                $elements[price_type].current.removeClass('price--special');
                plugin._animateTextUpdate($elements[price_type].current, price, effect, duration);
            }
        }

        // console.log(data);

    }

    _animateTextUpdate($element, value, effect, duration) {
        if (!$element.length) {
            return;
        }

        const plugin = this;

        duration = duration || 250;
        effect = effect || 'replace';

        const start = $element.text();

        if (start === value) {
            return;
        }

        switch (effect) {

            case 'fadeIn':
                $element.fadeOut(duration, function() {
                    $(this).text(value).fadeIn(duration);
                });
                break;

            case 'counter':
                if (!plugin.isNumeric(start) || !plugin.isNumeric(value)) {
                    $element.text(value);
                    return;
                }

                if (Math.floor(start) === Math.floor(value)) {
                    return;
                }

                $({numberValue: start}).animate({numberValue: value}, {
                    duration: duration,
                    easing: 'swing',
                    progress: function() {
                        $element.text(Math.ceil(this.numberValue));
                        // $element.text(Math.floor(this.numberValue*100)/100);
                    },
                    complete: function() {
                        $element.text(value);
                    }
                });

                break;

            case 'replace':
            default:
                $element.text(value);
                break;
        }

    }

    _switchQuantity($button) {

        const plugin = this;
        const $input = plugin.getOuter($button).find('input[name^=quantity]');

        if(!$button.length || !$input.length) {
            return;
        }

        const operation = $button.data('type');
        const min = Number($input.attr('min')) || plugin.default.quantity.min;
        const max = Number($input.attr('max')) || plugin.default.quantity.max;
        const step = Number($input.data('step')) || plugin.default.quantity.step;

        const quantity = Number($input.val());

        let value = min;
        if (plugin.isNumeric(quantity)) {
            value = quantity;
        }

        switch (operation)  {
            case 'increase':
                value += step;
                if (value > max) {
                    value = max;
                }
                break;

            case 'decrease':
                value = value - step;
                if (value < min) {
                    value = min;
                }
                break;
        }

        if (value !== quantity) {
            $input.val(value);
            plugin._updateQuantityInput($input);
        }

    }

    _updateQuantityInput($input) {

        const plugin = this;

        if(!$input.length) {
            return;
        }

        $input.val(plugin.getInputQuantity($input));

        if(plugin.ajaxQuantityTimeout !== 0) {
            clearTimeout(plugin.ajaxQuantityTimeout);
        }

        plugin.ajaxQuantityTimeout = setTimeout(function(){
            plugin.ajaxQuantityTimeout = 0;
            $input.trigger('change');
        }, 300);

    }

    // HELPERS
    getOuter($element) {
        let $outer = $element.closest(this.selector.item);
        if (!$outer.length) {
            $outer = $element.closest(this.selector.card);
        }

        if ($outer.length) {
            return $outer;
        }

        return {
            length: null,
            find: function () {
                return {
                    length: null
                }
            }
        };
    }

    getInputQuantity($input) {
        const plugin = this;

        if(!$input.length) {
            return;
        }

        const quantity = Number($input.val());
        const min = Number($input.attr('min')) || plugin.default.quantity.min;
        const max = Number($input.attr('max')) || plugin.default.quantity.max;

        let value = quantity;
        if (!plugin.isNumeric(value)) {
            value = min;
        }

        if (value > max) {
            value = max;
        }
        if (value < min) {
            value = min;
        }

        if (quantity !== value) {
            $input.val(value);
        }

        return value;
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

}