import {Notify} from "./common";

export class Checkout {

    constructor() {

        this.$widget = $("#cleverCheckout");

        if (!this.$widget.length) {
            return;
        }

        this.notify = new Notify();

        this.cache = {
            querySelector: {}
        };

        this.timeout = {
            quantity: 0,
            cart: 0,
        };

        this.api = {
            login: '/index.php?route=account/login/auth&token=',
            register: '/index.php?route=extension/plugin/clever_checkout/register&token=',

            country: '/index.php?route=extension/plugin/clever_checkout/ajax_helper&mode=country&country_id=',
            cart: '/index.php?route=extension/plugin/clever_checkout/ajax&mode=cart',
            amount: '/index.php?route=extension/plugin/clever_checkout/ajax&mode=amount',
            shipping_method: '/index.php?route=extension/plugin/clever_checkout/ajax&mode=shipping_method',

            save: '/index.php?route=extension/plugin/clever_checkout/ajax&mode=save',




            price_mode: 'index.php?route=common/cart/price_mode',
        };

        this.selector = {
            step: {
                container: '.step',
                button: '.step-button',
            },
            module: {
                module: '.checkout-module',
                cart: "#cart-details",
                recommendation: "#cart-recommendation",
                totals: "#cart-total",
                shipping_method: "#shipping-method",
                contacts: "#contacts",
                address: "#payment-address",
                price_mode: '.checkout__price-mode',
            },
            input: {

                shipping_method: '[name="shipping_method"]',

                comment: 'textarea#comment',


                country: '[name="country_id"]',
                zone: '[name=\'zone_id\']',
                select2: '.select2',

                quantity: '.js-cartQuantity',
            }
        };

        this._bindEvents();
        this._init();
    }

    _init() {

        const plugin = this;

        plugin._$el(plugin.selector.input.country).trigger('change');

        // $.fn.select2.defaults.set("theme", "bootstrap4");
        // $.fn.select2.defaults.set("language", "ru");
        // plugin._$el(plugin.selector.input.select2).select2({
        //     //minimumResultsForSearch: Infinity
        // });

    }

    _bindEvents() {

        const plugin = this;

        // ORDER
        plugin._$el(plugin.selector.module.contacts).on('change keyup paste cut', ':input', function () {
            plugin.saveContact($(this).attr('name'), $(this).val(), $(this));
        });

        // ADDRESS
        plugin._$el(plugin.selector.module.address).on('change keyup paste cut', ':input', function () {
            plugin.saveAddress($(this).attr('name'), $(this).val(), $(this), function () {
                plugin.loadShipping();
            });
        });

        // SHIPPING METHOD
        plugin._$el(plugin.selector.module.shipping_method).on('change', ':input', function () {
            plugin.saveData('shipping_method', $(this).val(), function () {
                plugin.loadTotals();
            });
        });

        // REGISTER


        // CART UPDATED
        plugin._$el(plugin.selector.module.cart).on('updated', function (e) {
            console.log('cart updated');
            plugin.loadCart();
        });



        // COUNTRY
        plugin._$el(plugin.selector.input.country).on('change', function () {
            const $parent = $(this).closest(plugin.selector.module.module);
            const $zoneSelect = $parent.find(plugin.selector.input.zone);
            plugin.loadZones(this.value, $zoneSelect);
        });

        // COMMENT
        plugin._$el(plugin.selector.input.comment).on('change keydown keyup paste cut', function () {

            const $area = $(this),
                newHeight = this.scrollHeight + 2;

            $area.css({'height': 0});

            if (newHeight >= 150) {
                $area.css({'height': 150 + 'px', 'overflow': 'auto'});
            } else {
                $area.css({'height': newHeight + 'px', 'overflow': 'hidden'});
            }
        });

        plugin._$el(plugin.selector.input.comment).on('change', function () {
            plugin.saveData('comment', $(this).val());
        });

        // NOTIFY
        // $(document).on('click', 'div.checkout-alert', function () {
        //     notify.hide($(this));
        // });

        // PASS HELPER
        $(document).on('click', '.psw-sh__toggle', function () {
            plugin.passwordToggle($(this).parent('.psw-sh').find('input'));
        });
        $(document).on('click', '.psw-gen', function () {
            var $input = $(this).closest('.card').find('input');
            $input.val(plugin.generatePass());
            plugin.passwordToggle($input, 'show');

            $input.removeClass('has-error');
            $input.next('.field-error').remove();
        });
    }

    loadShipping() {
        const plugin = this;

        const $shippingMethod = plugin._$el(plugin.selector.module.shipping_method);

        $.ajax({
            url: plugin.api.shipping_method,
            type: 'get',
            dataType: 'json',
            beforeSend: function() {
                $shippingMethod.addClass('loading');
            },
            complete: function() {
                $shippingMethod.removeClass('loading');
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        }).done(function (json) {
            if (json.html) {
                $shippingMethod.html(json.html);
            }
            //
            // console.log($shippingMethod);
            // console.log(json);
        });

        plugin.loadTotals();
    }

    loadTotals() {
        const plugin = this;

        const $totals = plugin._$el(plugin.selector.module.totals);

        $.ajax({
            url: plugin.api.cart + '&timestamp=' + new Date().getTime(),
            type: 'get',
            dataType: 'json',
            beforeSend: function() {
                $totals.addClass('loading');
            },
            complete: function() {
                $totals.removeClass('loading');
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        }).done(function (json) {

            if (json.totals) {
                const totalHtml = json.totals || '';
                $totals.html(totalHtml);
            }
        });
    }

    loadCart() {

        const plugin = this;

        const $cart = plugin._$el(plugin.selector.module.cart);
        const $recommendation = plugin._$el(plugin.selector.module.recommendation);
        const $priceMode = plugin._$el(plugin.selector.module.price_mode);
        const $totals = plugin._$el(plugin.selector.module.totals);

        $.ajax({
            url: plugin.api.cart + '&timestamp=' + new Date().getTime(),
            type: 'get',
            dataType: 'json',
            beforeSend: function() {
                $cart.addClass('loading');
                $totals.addClass('loading');
                $priceMode.addClass('loading');
            },
            complete: function() {
                $cart.removeClass('loading');
                $totals.removeClass('loading');
                $priceMode.removeClass('loading');
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        }).done(function (json) {

            console.log(json);

            if (json) {
                var cartHtml = json.products || '';
                $cart.html(cartHtml);

                $recommendation.html(json.recommendation || '');

                var totalHtml = json.totals || '';
                $totals.html(totalHtml);

                var priceModeHtml = json.price_mode || '';
                $priceMode.html(priceModeHtml);
            }

            var $minimum_order = $('.alert.alert--minimum_order');
            if (json.alerts && json.alerts.minimum_order) {
                if ($minimum_order.length) {
                    $minimum_order.html(json.alerts.minimum_order);
                } else {
                    $('.logged-customer').after('<div class="alert alert-danger shadow mt-3 alert--minimum_order sticky">' +
                        json.alerts.minimum_order + '</div>');
                }

                $('#button-checkout').addClass('disabled');
            } else {
                $minimum_order.remove();
                $('#button-checkout').removeClass('disabled');
            }
        });
    }

    loadZones(country_id, $zoneSelect) {

        const plugin = this;

        // console.log(country_id, $zoneSelect.length);

        if (!country_id || !$zoneSelect.length) {
            return;
        }

        const defaultValue = Number($zoneSelect.data('default'));

        // console.log(defaultValue);

        $.ajax({
            url: plugin.api.country + country_id,
            dataType: 'json',
            cache: false,
            beforeSend: function () {
                $zoneSelect.prop('disabled', true).addClass('loading');
            },
            complete: function () {
                $zoneSelect.prop('disabled', false).addClass('loading');
            },
            success: function (json) {

                // if (json['postcode_required'] == '1') {
                //     $('#shipping-postcode-required').addClass('required');
                // } else {
                //     $('#shipping-postcode-required').removeClass('required');
                // }

                // console.log(json);

                var html = '',
                    selected = '',
                    i = 0;

                if (json.zone) {

                    html += '<option value="0">---</option>';

                    for (i = 0; i < json.zone.length; i++) {

                        selected = '';
                        if (Number(json.zone[i]['zone_id']) === defaultValue) {
                            selected = ' selected="selected"';
                        }

                        html += '<option value="' + json.zone[i]['zone_id'] + '"' + selected + '>' + json['zone'][i]['name'] + '</option>';
                    }
                } else {
                    html += '<option value="0" selected="selected">---</option>';
                }

                $zoneSelect.html(html).trigger('change');
            },
        });
    }

    saveContact(key, value, $input, callback) {
        const plugin = this;

        // console.log(value);

        $.ajax({
            url: plugin.api.save,
            type: 'post',
            dataType: 'json',
            cache: false,
            data: {
                key: key,
                value: value
            },
            beforeSend: function () {

            },
            complete: function () {

            },
            success: function (json) {
                // console.log(json);

                if (!$input.length) {
                    return;
                }

                let $error = $input.next('small');
                if (!$error.length) {
                    $input.after('<small class="field-error form-text mt-1"></small>');
                    $error = $input.next('small');
                }

                if (json.error) {
                    $error.text(json.error);
                } else {
                    $input.removeClass('has-error');
                    $error.text('');
                }

                callback();
            },
        })
    }

    saveAddress(key, value, $input, callback) {
        const plugin = this;

        // console.log(key, value);

        $.ajax({
            url: plugin.api.save,
            type: 'post',
            dataType: 'json',
            cache: false,
            data: {
                key: key,
                value: value
            },
            beforeSend: function () {

            },
            complete: function () {

            },
            success: function (json) {

                // console.log(json);

                if (!$input.length) {
                    return;
                }

                let $error = $input.next('small');
                if (!$error.length) {
                    $input.after('<small class="field-error form-text mt-1"></small>');
                    $error = $input.next('small');
                }

                if (json.error) {
                    $error.text(json.error);
                } else {
                    $input.removeClass('has-error');
                    $error.text('');
                }

                callback();
            },
        })
    }

    saveData(key, value, callback) {

        const plugin = this;

        // console.log(value);

        $.ajax({
            url: plugin.api.save,
            type: 'post',
            data: {
                key: key,
                value: value
            }
        }).done(function (json) {
            callback();
        });
    }


    _$el(selector) {
        const plugin = this;

        if (!plugin.cache.querySelector.selector) {
            plugin.cache.querySelector[selector] = $(selector);
        }
        return plugin.cache.querySelector[selector];
    }

    generatePass() {
        var min = Math.ceil(7);
        var max = Math.floor(12);
        var strong = Math.floor(Math.random() * (max - min + 1)) + min;
        var dic = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = dic.length; i < strong; ++i) {
            retVal += dic.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    passwordToggle($input, state) {

        if (!$input.length) {
            return;
        }

        state = state || false;
        if (!state) {
            state = ($input.attr('type') === "password") ? 'show' : 'hide';
        }

        var $outer = $input.parent('.psw-sh');

        switch (state) {
            case 'show':
                $outer.addClass('psw--show');
                $input.attr('type', 'text');
                break;

            default:
                $outer.removeClass('psw--show');
                $input.attr('type', 'password');
                break;
        }
    }
}