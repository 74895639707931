import 'scrollport-js'

export class ScrollTo {

    constructor() {

        this.$ontop = $('.scrolltop');
        this.$ontopWrapper = $('.scroll-top-wrapper');
    }

    init() {

        const plugin = this;




        $(document).on('click', '.js-scrollport', function (e) {

            e.preventDefault();

            plugin.scroll($(this));
        });


        /// ScrollTop
        if (plugin.$ontop.length > 0) {

            $(document).on('scroll', function () {
                if ($(window).scrollTop() > 500) {
                    plugin.$ontopWrapper.addClass('show');
                } else {
                    plugin.$ontopWrapper.removeClass('show');
                }
            });

            plugin.$ontop.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                const element = $('body');
                const offset = element.offset();
                const offsetTop = offset.top;

                $('html, body').animate({scrollTop: offsetTop}, 200, 'linear');
            });
        }



    }

    scroll($element) {

        const plugin = this;

        let mode = $element.data('mode') || 'roll',  // 'usual', 'roll', 'hard', 'soft'
            distance = $element.data('distance') || 100,
            delta = $element.data('delta') || 30,
            targetStr = $element.data('href') || $element.data('target') || $element.attr('href'),
            targetTab = $element.data('show-tab') || false,
            targetCollapse = $element.data('show-collapse') || false;


        // console.log(target);

        if (!targetStr) {
            return true;
        }

        let target = '';

        let targetParts = targetStr.split('#');
        if (targetParts.length) {
            target = '#' + targetParts[targetParts.length - 1];
        }


        if ($.fn.scrollport && target && $(target).length && target.match("^#") && $(target).length) {

            if (targetTab && $(targetTab).length && $(targetTab)) {
                $(targetTab).tab('show');
            }

            $.scrollport($(target), {
                mode: mode,
                distance: distance,
                delta: delta
            });

            // console.log($(targetTab).hasClass('tab-pane'));
        }

        if (targetCollapse && $(targetCollapse).length && $(targetCollapse)) {
            $(targetCollapse).collapse('show');
        }
    }

}