import StickyEvents from 'sticky-events';

export class Sticky {
    constructor() {

        this.stickyContainers = {};

        this.resize();
    }

    init() {

        const plugin = this;

        $('.sticky').each(function( index ) {

            const container = $(this).data('container') || 'document';

            if (!plugin.stickyContainers.hasOwnProperty(container)) {

                const breakpoint = Number($(this).data('sticky-breakpoint') || 0);

                if (plugin.windowWidth > breakpoint) {

                    plugin.stickyContainers[container] = true;

                    const stickyEvents = new StickyEvents({
                        container: (container === 'document') ? document : document.querySelector(container),
                        // enabled: true,
                        stickySelector: '.sticky'
                    });

                    // console.log(stickyEvents);

                    stickyEvents.enableEvents();

                    const { stickyElements, stickySelector } = stickyEvents;

                    stickyElements.forEach(sticky => {
                        sticky.addEventListener(StickyEvents.CHANGE, (event) => {
                            sticky.classList.toggle('state--stuck', event.detail.isSticky);
                        });
                    });

                    // console.log(stickyElements);
                }
            }
        });
    }

    resize() {
        this.windowWidth = $(window).width();

        this.init();
    }
}